import { render, staticRenderFns } from "./file-library.vue?vue&type=template&id=499fe4cd&scoped=true&"
import script from "./file-library.vue?vue&type=script&lang=js&"
export * from "./file-library.vue?vue&type=script&lang=js&"
import style0 from "./file-library.vue?vue&type=style&index=0&id=499fe4cd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "499fe4cd",
  null
  
)

export default component.exports