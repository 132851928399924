






import { createComponent, computed } from '@vue/composition-api';
import parseCSSVar from '@/utils/parse-css-var';

export default createComponent({
	props: {
		color: {
			type: String,
			default: '--input-background-color-alt'
		},
		minHeight: {
			type: Number,
			default: null
		},
		maxHeight: {
			type: Number,
			default: null
		},
		height: {
			type: Number,
			default: null
		},
		minWidth: {
			type: Number,
			default: null
		},
		maxWidth: {
			type: Number,
			default: null
		},
		width: {
			type: Number,
			default: null
		}
	},
	setup(props) {
		type Styles = {
			'--_v-sheet-color': string;
			'--_v-sheet-min-height'?: string;
			'--_v-sheet-max-height'?: string;
			'--_v-sheet-height'?: string;
			'--_v-sheet-min-width'?: string;
			'--_v-sheet-max-width'?: string;
			'--_v-sheet-width'?: string;
		};

		const styles = computed(() => {
			const styles: Styles = {
				'--_v-sheet-color': parseCSSVar(props.color)
			};

			if (props.minHeight) {
				styles['--_v-sheet-min-height'] = props.minHeight + 'px';
			}

			if (props.maxHeight) {
				styles['--_v-sheet-max-height'] = props.maxHeight + 'px';
			}

			if (props.height) {
				styles['--_v-sheet-height'] = props.height + 'px';
			}

			if (props.minWidth) {
				styles['--_v-sheet-min-width'] = props.minWidth + 'px';
			}

			if (props.maxWidth) {
				styles['--_v-sheet-max-width'] = props.maxWidth + 'px';
			}

			if (props.width) {
				styles['--_v-sheet-width'] = props.width + 'px';
			}

			return styles;
		});

		return { styles };
	}
});
