























import { createComponent, computed } from '@vue/composition-api';
import parseCSSVar from '@/utils/parse-css-var';

export default createComponent({
	props: {
		absolute: {
			type: Boolean,
			default: false
		},
		backgroundColor: {
			type: String,
			default: '--progress-background-color'
		},
		bottom: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: '--progress-background-color-accent'
		},
		fixed: {
			type: Boolean,
			default: false
		},
		height: {
			type: Number,
			default: 4
		},
		indeterminate: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
		top: {
			type: Boolean,
			default: false
		},
		value: {
			type: Number,
			default: 0
		}
	},
	setup(props) {
		const styles = computed<object>(() => ({
			'--_v-progress-linear-background-color': parseCSSVar(props.backgroundColor),
			'--_v-progress-linear-color': parseCSSVar(props.color),
			'--_v-progress-linear-height': props.height + 'px'
		}));

		return { styles };
	}
});
