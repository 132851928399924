var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.element,{tag:"component",staticClass:"v-card",class:{
		link: _vm.link,
		selected: _vm.selected,
		selectable: _vm.selectable,
		'selection-mode': _vm.selectionMode,
		disabled: _vm.disabled,
		'text-background': _vm.textBackground
	},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c(_vm.wrapperTag,{tag:"component",class:{
			'only-show-on-hover': _vm.onlyShowOnHover
		},attrs:{"to":_vm.to,"href":_vm.href,"target":"__blank"}},[(_vm.src || _vm.icon || _vm.$slots.icon)?_c('div',{staticClass:"header",class:{
				'big-image': _vm.bigImage && _vm.src && !_vm.error,
				'medium-image': _vm.mediumImage && _vm.src && !_vm.error
			},style:({ backgroundColor: ("var(--" + _vm.color + ")") })},[(_vm.selectable)?_c('button',{staticClass:"select",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select')}}},[_c('v-icon',{attrs:{"name":_vm.selectionIcon}})],1):_vm._e(),(_vm.src && !_vm.error)?_c('img',{attrs:{"alt":_vm.title,"src":_vm.src},on:{"error":_vm.onImageError}}):_vm._e(),(_vm.error)?_c('v-icon',{staticClass:"error icon",attrs:{"name":"broken_image","x-large":"","color":"white"}}):_vm._e(),(_vm.icon)?_c('v-icon',{staticClass:"icon",class:{ 'half-opacity': _vm.opacity === 'half' },attrs:{"name":_vm.icon,"x-large":"","color":"white"}}):_vm._e(),(_vm.$slots.icon)?_c('div',{staticClass:"custom-icon"},[_vm._t("icon")],2):_vm._e(),(_vm.label)?_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e()],1):_c('div',{staticClass:"header small",style:({ backgroundColor: ("var(--" + _vm.color + ")") })},[(_vm.selectable)?_c('button',{staticClass:"select",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('select')}}},[_c('v-icon',{attrs:{"name":_vm.selectionIcon}})],1):_vm._e()]),_c('div',{staticClass:"body",class:{ menu: _vm.options != null }},[_c('div',{staticClass:"main"},[(_vm.$slots.title)?_c('div',{staticClass:"title"},[_vm._t("title")],2):_c(_vm.titleElement,{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.title),expression:"title"}],tag:"component",staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.$slots.subtitle)?_c('div',{staticClass:"subtitle type-note"},[_vm._t("subtitle")],2):(_vm.subtitle)?_c('p',{staticClass:"subtitle type-note"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()],1),(_vm.options)?_c('v-contextual-menu',{staticClass:"context",attrs:{"disabled":_vm.disabled,"options":_vm.options},on:{"click":function($event){return _vm.$emit($event)}}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }