import { render, staticRenderFns } from "./tag.vue?vue&type=template&id=1f9e4926&scoped=true&functional=true&"
import script from "./tag.vue?vue&type=script&lang=js&"
export * from "./tag.vue?vue&type=script&lang=js&"
import style0 from "./tag.vue?vue&type=style&index=0&id=1f9e4926&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "1f9e4926",
  null
  
)

/* custom blocks */
import block0 from "./tag.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports