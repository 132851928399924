




import { createComponent, computed } from '@vue/composition-api';
import parseCSSVar from '@/utils/parse-css-var';

export default createComponent({
	props: {
		color: {
			type: String,
			default: '--loading-background-color-accent'
		},
		backgroundColor: {
			type: String,
			default: '--loading-background-color'
		},
		size: {
			type: Number,
			default: null
		},
		lineSize: {
			type: Number,
			default: null
		},
		speed: {
			type: String,
			default: '1s'
		},
		xSmall: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		xLarge: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		type Styles = {
			'--_v-spinner-color': string;
			'--_v-spinner-background-color': string;
			'--_v-spinner-speed': string;
			'--_v-spinner-size'?: string;
			'--_v-spinner-line-size'?: string;
		};

		const styles = computed(() => {
			const styles: Styles = {
				'--_v-spinner-color': parseCSSVar(props.color),
				'--_v-spinner-background-color': parseCSSVar(props.backgroundColor),
				'--_v-spinner-speed': props.speed
			};

			if (props.size) {
				styles['--_v-spinner-size'] = `${props.size}px`;
			}

			if (props.lineSize) {
				styles['--_v-spinner-line-size'] = `${props.lineSize}px`;
			}

			return styles;
		});

		const sizeClass = computed<string | null>(() => {
			if (props.xSmall) return 'x-small';
			if (props.small) return 'small';
			if (props.large) return 'large';
			if (props.xLarge) return 'x-large';
			return null;
		});

		return {
			styles,
			sizeClass
		};
	}
});
