


















import { createComponent, reactive, computed, Ref } from '@vue/composition-api';
import parseCSSVar from '@/utils/parse-css-var';

export default createComponent({
	props: {
		block: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},
		icon: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: '--button-primary-text-color'
		},
		backgroundColor: {
			type: String,
			default: '--button-primary-background-color'
		},
		hoverColor: {
			type: String,
			default: '--button-primary-text-color'
		},
		hoverBackgroundColor: {
			type: String,
			default: '--button-primary-background-color-hover'
		},
		type: {
			type: String,
			default: 'button'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		width: {
			type: Number,
			default: null
		},
		xSmall: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		xLarge: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		interface Styles {
			'--_v-button-color': string;
			'--_v-button-background-color': string;
			'--_v-button-hover-color': string;
			'--_v-button-hover-background-color': string;
			width?: string;
		}

		const styles = computed<Styles>(() => {
			let styles: Styles = {
				'--_v-button-color': parseCSSVar(props.color),
				'--_v-button-background-color': parseCSSVar(props.backgroundColor),
				'--_v-button-hover-color': parseCSSVar(props.hoverColor),
				'--_v-button-hover-background-color': parseCSSVar(props.hoverBackgroundColor)
			};

			if (props.width && +props.width > 0) {
				styles.width = props.width + 'px';
			}

			return styles;
		});

		const sizeClass = computed<string | null>(() => {
			if (props.xSmall) return 'x-small';
			if (props.small) return 'small';
			if (props.large) return 'large';
			if (props.xLarge) return 'x-large';
			return null;
		});

		return { styles, sizeClass };
	}
});
