var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-bar"},[_c('v-logo',{staticClass:"logo"}),_vm._l((_vm.modules),function(singleModule){return [(singleModule.link.startsWith('http'))?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
				content: singleModule.name,
				boundariesElement: 'body'
			}),expression:"{\n\t\t\t\tcontent: singleModule.name,\n\t\t\t\tboundariesElement: 'body'\n\t\t\t}",modifiers:{"left":true}}],key:singleModule.link,staticClass:"link",class:singleModule.class,attrs:{"href":singleModule.link,"target":"__blank"}},[_c('v-icon',{staticClass:"icon",attrs:{"name":singleModule.icon || 'box',"color":("--" + (singleModule.color || 'blue-grey-400'))}})],1):_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
				content: singleModule.name,
				boundariesElement: 'body'
			}),expression:"{\n\t\t\t\tcontent: singleModule.name,\n\t\t\t\tboundariesElement: 'body'\n\t\t\t}",modifiers:{"left":true}}],key:singleModule.link,staticClass:"link",class:singleModule.class,attrs:{"to":singleModule.link}},[_c('v-icon',{staticClass:"icon",attrs:{"name":singleModule.icon || 'box',"color":("--" + (singleModule.color || 'blue-grey-400'))}})],1)]}),_c('div',{staticClass:"spacer"}),_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
			content: _vm.currentUser.first_name + ' ' + _vm.currentUser.last_name,
			boundariesElement: 'body'
		}),expression:"{\n\t\t\tcontent: currentUser.first_name + ' ' + currentUser.last_name,\n\t\t\tboundariesElement: 'body'\n\t\t}",modifiers:{"left":true}}],staticClass:"edit-user",class:{ smoke: _vm.avatarURL },attrs:{"to":("/" + _vm.currentProjectKey + "/users/" + (_vm.currentUser.id))}},[_c('v-avatar',{attrs:{"x-large":"","tile":"","color":"--module-background-color-active"}},[(_vm.avatarURL)?_c('img',{attrs:{"src":_vm.avatarURL}}):_c('v-icon',{attrs:{"name":"person","color":"--blue-grey-400"}})],1)],1),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:({
			content: _vm.$t('sign_out'),
			boundariesElement: 'body'
		}),expression:"{\n\t\t\tcontent: $t('sign_out'),\n\t\t\tboundariesElement: 'body'\n\t\t}",modifiers:{"left":true}}],staticClass:"sign-out",attrs:{"type":"button"},on:{"click":function($event){_vm.confirmSignOut = true}}},[_c('v-icon',{attrs:{"name":"logout","color":"--blue-grey-400"}})],1),(_vm.confirmSignOut)?_c('portal',{attrs:{"to":"modal"}},[_c('v-confirm',{attrs:{"busy":_vm.confirmSignOutLoading,"message":_vm.editing ? _vm.$t('sign_out_confirm_edits') : _vm.$t('sign_out_confirm'),"confirm-text":_vm.$t('sign_out')},on:{"cancel":function($event){_vm.confirmSignOut = false},"confirm":_vm.signOut}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }