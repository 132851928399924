






import { createComponent, computed } from '@vue/composition-api';
import parseCSSVar from '../../utils/parse-css-var';

export default createComponent({
	props: {
		color: {
			type: String,
			default: '--avatar-background-color'
		},
		size: {
			type: Number,
			default: null
		},
		tile: {
			type: Boolean,
			default: false
		},
		xSmall: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		xLarge: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		type Styles = {
			'--_v-avatar-color': string;
			'--_v-avatar-size'?: string;
		};

		const styles = computed(() => {
			const styles: Styles = {
				'--_v-avatar-color': parseCSSVar(props.color)
			};

			if (props.size) {
				styles['--_v-avatar-size'] = props.size + 'px';
			}

			return styles;
		});

		const sizeClass = computed<string | null>(() => {
			if (props.xSmall) return 'x-small';
			if (props.small) return 'small';
			if (props.large) return 'large';
			if (props.xLarge) return 'x-large';
			return null;
		});

		return { styles, sizeClass };
	}
});
