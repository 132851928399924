// Reset store
export const RESET = '🔥 Reset State';

// Auth
export const LOGIN_PENDING = '🔒 Login Pending';
export const LOGIN_SUCCESS = '🔒✅ Login Successful';
export const LOGIN_FAILED = '🔒❌ Login Failed';
export const LOGOUT = '🔒 Logout';
export const REFRESH_TOKEN = '🔒 Refresh Token';
export const REMOVE_AUTH_ERROR = '🔒 Remove Auth Error';
export const CHANGE_API = '🔒 Change API';
export const SWITCH_PROJECT = '🔒 Switch API Project ';

// Bookmarks
export const SET_BOOKMARKS = '🔖 Set Bookmarks';
export const ADD_BOOKMARK = '🔖 Add Bookmark';
export const DELETE_BOOKMARK = '🔖 Delete Bookmark';

// Collections
export const SET_COLLECTIONS = '🗄 Set Collections';
export const ADD_COLLECTION = '🗄 Add Collection';
export const DELETE_COLLECTION = '🗄 Remove Collection';
export const UPDATE_COLLECTION = '🗄 Update Collection';

// Edits
export const DISCARD_CHANGES = '📝 Discard Changes';
export const ITEM_CREATED = '📝 Item Saved';
export const START_EDITING = '📝 Start Editing';
export const UNSTAGE_VALUE = '📝 Unstage Value';
export const STAGE_VALUE = '📝 Stage Value';

// Fields
export const ADD_FIELD = '📌 Add Field';
export const UPDATE_FIELD = '📌 Update Field';
export const UPDATE_FIELDS = '📌 Update Fields';
export const REMOVE_FIELD = '📌 Remove Field';

// Extensions
export const SET_INTERFACES = '📦 Set Interfaces';
export const SET_LAYOUTS = '📦 Set Layouts';
export const SET_MODULES = '📦 Set Modules';

// Hydrate
export const STORE_HYDRATED = '🏪 Store Hydrated';
export const HYDRATING_FAILED = '🏪❌ Store Hydration Failed';

// Permissions
export const SET_PERMISSIONS = '🙅🏻‍♂️ Set Permissions';
export const ADD_PERMISSION = '🙅🏻‍♂️ Add Permissions';

// Latency Check
export const LATENCY = '⏱ Latency Check';

// Loading queue
export const LOADING_START = '⏳ Queue Request';
export const LOADING_FINISHED = '⏳ Remove Request from Queue';

// Me
export const SET_CURRENT_USER = '👨🏻‍💻✅ Set Current User';
export const UPDATE_CURRENT_USER = '👨🏻‍💻 Current User Updated';

// Relations
export const SET_RELATIONS = '❤️ Set Relations';
export const ADD_RELATION = '❤️ Add Relation';
export const UPDATE_RELATION = '❤️ Update Relation';

// Server Info
export const SET_SERVER_INFO = '🔮 Set Server Info';

// Sidebars
export const TOGGLE_NAV = '🎨 Toggle Nav Sidebar';
export const TOGGLE_INFO = '🎨 Toggle Info Sidebar';

// Users
export const SET_USERS = '👯‍♀️ Set Users';

// Notifications
export const PUSH_NOTIFICATION = '💬 Push Notification';
export const REMOVE_NOTIFICATION = '💬❌ Remove Notification';

// Projects
export const SET_CURRENT_PROJECT = '🌟 Set Current Project';
export const UPDATE_PROJECT = '🌟 Update Project';
export const SET_PROJECT_STATUS = '🌟 Set Status';
export const INIT_PROJECTS = '🌟 Initialize Projects';

// Settings
export const SET_SETTINGS = '⚙️ Set Settings';
export const SET_SETTING = '⚙️ Set Setting';
