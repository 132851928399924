













import { createComponent, reactive, computed } from '@vue/composition-api';
import getSizeClass from '@/utils/get-size-class';
import parseCSSVar from '@/utils/parse-css-var';
import CustomIconBox from './custom-icons/box.vue';

const customIcons: string[] = ['box'];

export default createComponent({
	components: { CustomIconBox },
	props: {
		name: {
			type: String,
			required: true
		},
		color: {
			type: String,
			default: 'currentColor'
		},
		outline: {
			type: Boolean,
			default: false
		},
		sup: {
			type: Boolean,
			default: false
		},
		size: {
			type: Number,
			default: null
		},
		xSmall: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		xLarge: {
			type: Boolean,
			default: false
		},
		left: {
			type: Boolean,
			default: false
		},
		right: {
			type: Boolean,
			default: false
		}
	},

	setup(props, { emit, listeners }) {
		const sizeClass = computed<string | null>(() => {
			if (props.sup) return 'sup';
			if (props.xSmall) return 'x-small';
			if (props.small) return 'small';
			if (props.large) return 'large';
			if (props.xLarge) return 'x-large';
			return null;
		});

		const customSize = computed<string | null>(() => {
			if (props.size) return `${props.size}px`;
			return null;
		});

		const colorStyle = computed<string>(() => {
			return parseCSSVar(props.color);
		});

		const customIconName = computed<string | null>(() => {
			if (customIcons.includes(props.name)) return `custom-icon-${props.name}`;
			return null;
		});

		const hasClick = computed<boolean>(() => listeners.hasOwnProperty('click'));

		return {
			sizeClass,
			colorStyle,
			customIconName,
			customSize,
			hasClick,
			emitClick
		};

		function emitClick(event: MouseEvent) {
			emit('click', event);
		}
	}
});
