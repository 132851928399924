var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"v-table",class:{ loading: _vm.loading },style:({ minWidth: _vm.totalWidth + 'px' }),on:{"scroll":_vm.onScroll}},[_c('div',{staticClass:"toolbar",class:{ shadow: _vm.scrolled }},[(_vm.manualSortField)?_c('div',{staticClass:"manual-sort cell",class:{ active: _vm.manualSorting }},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('enable_manual_sorting')),expression:"$t('enable_manual_sorting')"}],on:{"click":_vm.startManualSorting}},[_c('v-icon',{attrs:{"name":"sort"}})],1)]):_vm._e(),(_vm.selectable)?_c('div',{staticClass:"select cell"},[_c('v-checkbox',{attrs:{"id":"select-all","inputValue":_vm.allSelected,"name":"select-all","value":"all"},on:{"change":_vm.selectAll}})],1):_vm._e(),_vm._l((_vm.columns),function(ref,index){
var field = ref.field;
var name = ref.name;
return _c('div',{key:field,staticClass:"cell",style:({
				flexBasis: _vm.widths && _vm.widths[field] ? _vm.widths[field] + 'px' : null
			})},[(_vm.sortable && !_vm.isRelational(_vm.columns[index].fieldInfo))?_c('button',{staticClass:"sort type-table-head no-wrap",class:{ active: _vm.sortVal.field === field },on:{"click":function($event){return _vm.updateSort(field)}}},[_vm._v(" "+_vm._s(_vm.widths[field] > 40 ? _vm.$helpers.formatField(field, _vm.columns[index].fieldInfo.collection) : null)+" "),_c('v-icon',{staticClass:"sort-icon",class:_vm.sortVal.asc ? 'asc' : 'desc',attrs:{"color":"--input-border-color-hover","name":"sort"}})],1):_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
					_vm.isRelational(_vm.columns[index].fieldInfo)
						? _vm.$t('cant_sort_by_this_field')
						: undefined
				),expression:"\n\t\t\t\t\tisRelational(columns[index].fieldInfo)\n\t\t\t\t\t\t? $t('cant_sort_by_this_field')\n\t\t\t\t\t\t: undefined\n\t\t\t\t"}],staticClass:"type-table-head"},[_vm._v(" "+_vm._s(_vm.widths[field] > 40 ? name : null)+" ")]),(_vm.resizeable && index !== _vm.columns.length - 1)?_c('div',{staticClass:"drag-handle",attrs:{"draggable":""},on:{"drag":function($event){return _vm.drag(field, $event)},"dragstart":_vm.hideDragImage,"dragend":_vm.dragEnd}},[_c('div',{staticClass:"drag-handle-line"})]):_vm._e()])})],2),_c('div',{staticClass:"body",class:{ loading: _vm.loading, dragging: _vm.dragging }},[(_vm.loading && _vm.items.length === 0)?_c('div',{staticClass:"loader"},_vm._l((50),function(n){return _c('div',{key:n,staticClass:"row",style:({ height: _vm.rowHeight + 'px' })})}),0):_vm._e(),_c(_vm.manualSorting ? 'draggable' : 'div',{tag:"component",attrs:{"options":{ handle: '.manual-sort' }},on:{"start":_vm.startSort,"end":_vm.saveSort},model:{value:(_vm.itemsManuallySorted),callback:function ($$v) {_vm.itemsManuallySorted=$$v},expression:"itemsManuallySorted"}},[(_vm.link)?_vm._l((_vm.itemsArray),function(row){return _c('div',{key:row[_vm.primaryKeyField],staticClass:"link row",class:{
						selected: _vm.selection && _vm.selection.includes(row[_vm.primaryKeyField])
					},style:({ height: _vm.rowHeight + 'px' }),attrs:{"tabindex":"0","role":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push(row[_vm.link])},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.$router.push(row[_vm.link])}}},[(_vm.manualSortField)?_c('div',{staticClass:"manual-sort cell",class:{ active: _vm.manualSorting },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('v-icon',{attrs:{"name":"drag_handle"}})],1):_vm._e(),(_vm.selectable)?_c('div',{staticClass:"cell select",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{attrs:{"id":'check-' + row[_vm.primaryKeyField],"value":("" + (row[_vm.primaryKeyField])),"inputValue":_vm.selection.includes(row[_vm.primaryKeyField])},on:{"change":function($event){return _vm.toggleCheckbox(row[_vm.primaryKeyField])}}})],1):_vm._e(),_vm._l((_vm.columns),function(ref){
					var field = ref.field;
					var fieldInfo = ref.fieldInfo;
return _c('div',{key:field,staticClass:"cell",style:({
							flexBasis: _vm.widths && _vm.widths[field] ? _vm.widths[field] + 'px' : null
						})},[(
								(row[field] === '' || _vm.isNil(row[field])) &&
									fieldInfo &&
									fieldInfo.type.toLowerCase() !== 'alias'
							)?_c('div',{staticClass:"empty"},[_vm._v(" -- ")]):(
								_vm.useInterfaces &&
									(!_vm.isNil(row[field]) ||
										(fieldInfo && fieldInfo.type.toLowerCase() === 'alias'))
							)?_c('v-ext-display',{staticClass:"ellipsis",attrs:{"id":field,"interface-type":fieldInfo.interface,"name":field,"values":row,"collection":_vm.collection,"type":fieldInfo.type,"datatype":fieldInfo.datatype,"options":fieldInfo.options,"value":row[field],"relation":fieldInfo.relation}}):[_vm._v(" "+_vm._s(row[field])+" ")]],2)})],2)}):_vm._l((_vm.itemsArray),function(row){return _c('div',{key:row[_vm.primaryKeyField],staticClass:"row",style:({ height: _vm.rowHeight + 'px' })},[(_vm.selectable)?_c('div',{staticClass:"select",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{attrs:{"id":'check-' + row[_vm.primaryKeyField],"value":("" + (row[_vm.primaryKeyField])),"inputValue":_vm.selection.includes(row[_vm.primaryKeyField])},on:{"change":function($event){return _vm.toggleCheckbox(row[_vm.primaryKeyField])}}})],1):_vm._e(),_vm._l((_vm.columns),function(ref){
							var field = ref.field;
							var fieldInfo = ref.fieldInfo;
return _c('div',{key:field,staticClass:"cell",style:({
							flexBasis: _vm.widths && _vm.widths[field] ? _vm.widths[field] + 'px' : null
						})},[(row[field] === '' || _vm.isNil(row[field]))?_c('div',{staticClass:"empty"},[_vm._v(" -- ")]):(_vm.useInterfaces && !_vm.isNil(row[field]))?_c('v-ext-display',{attrs:{"id":field,"interface-type":fieldInfo.interface,"name":field,"collection":_vm.collection,"type":fieldInfo.type,"options":fieldInfo.options,"value":row[field]}}):[_vm._v(" "+_vm._s(row[field])+" ")]],2)})],2)})],2)],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.lazyLoading)?_c('div',{staticClass:"lazy-loader"},[_c('v-spinner',{attrs:{"color":"--blue-grey-300","background-color":"--blue-grey-200"}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }